import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // importez le plugin timezone
import customParseFormat from "dayjs/plugin/customParseFormat"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isToday from "dayjs/plugin/isToday";

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Europe/Paris");
dayjs.locale('fr');
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isToday);

// Auth
import Widget from "./widget";
import dayjs from "dayjs";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Widget />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
