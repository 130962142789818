import axios from "axios"
import { getAuthorizedConfig } from "../../helpers/helpers"

export interface StripeConnectVerificationOutput {
  accountCompleted: boolean
  accountId: string
}

export async function stripeConnectVerification(
  clientId: string | number,
): Promise<StripeConnectVerificationOutput> {
  const config = getAuthorizedConfig("GET")
  config["url"] = `${process.env.REACT_APP_API_URL}/clients/stripe/verification/${clientId}`
  const response = await axios.request(config)
  return response.data
}
