const bookingPayment: any = {
  en: {
    pay: "Pay and book",
    book: "Book",
    previous: "Previous",
    bookingInProgress: "Booking in progress",
    ticketSelection: "Ticket selection",
    payOneCard: "Pay with one card",
    payManyCards: "Pay with many cards",
    activatePromoCode: "Activate",
    promoCode: "Promo code",
    extraInfosTitle: "Informations required for booking",
    readAndAccept: "I have read and accept the",
    accept: "I accept the",
    processingConditions: "conditions de traitement des données conformes au RGPD",
    salesConditions: "general conditions of sales",

    GDPRNoticesTitle: "GDPR Compliant Information Notices",
    GDPRNoticesContent: `The Personal Data collected is processed by HOPLEISURE SAS (SIREN: 952 553 139) in its capacity as data controller and used to guarantee the traceability of the reservation and the associated transaction between the user and the company operating the experience, in the name and on behalf of which HOPLEISURE SAS has made this reservation technology available.

The information collected may be used only by the company operating the experience for accounting or sales canvassing purposes. HOPLEISURE has no rights over the Personal Data.

In accordance with Regulation (EU) 2016/679 of 27 April 2016 on the protection of personal data and the amended Data Protection Act of 6 January 1978, you have the right to access, rectify, erase, transfer and limit the processing of your personal data, as well as the right to communicate instructions on the fate of your data after your death. You may also, for legitimate reasons, object to the processing of your personal data. You can exercise your rights by contacting Ricardo Vasques at the following address: ricardo.vasques@hopleisure.com or at the following postal address: 25 RUE DES LAITIERES 94300 VINCENNES

For more information on the Processing of your Personal Data by HOPLEISURE SAS, please consult our privacy policy available on our website at the following address: https://hopleisure.com/politique-de-confidentialite

Anonymized transactional data not falling under Personal Data will however be usable by HOPLEISURE SAS including for commercial or statistical purposes.`,
    salesConditionsTitle: "General conditions of sale",
    salesConditionsContent: `The sales contract between the operating company in the name and on behalf of which HOPLEISURE has developed this reservation technology and the user is concluded as soon as the user makes the payment on the tools made available by HOPLEISURE SAS in partnership with https://stripe.com/fr.

All the information necessary for the conclusion of the sales contract is made available to the user by the reservation tool (identity of the seller, date, time, price of the reservation, co-contractor, purpose of the purchase and conditions of reimbursement and cancellation, etc.) as well as by the website on which the purchase was made. The buyer acknowledges by checking the box "I accept the general conditions of sale" having had sufficient information at his disposal to make an informed purchasing decision.`,
    acceptConditions: "I HAVE READ AND ACCEPT THESE CONDITIONS",
    error: {
      paymentProblem: "Payment failed",
      promoCodeNotAvailable:
        "The quantity of tickets selected is greater than the quantity of tickets available for this promo code",
    },
    form: {
      name: {
        label: "Your firstname and lastname",
        error: "Complete name is required",
      },
      email: {
        label: "Your email address",
        error: "Email address is required",
      },
      phoneNumber: {
        label: "Phone number",
        error: "Phone number is required",
      },
      extraInfo: {
        error: {
          empty: "Answer is required",
          min: "Answer should be at least 3 characters",
        },
      },
      checkboxes: {
        error: "Please accept the T&Cs and the personal data processing policy.",
      },
    },
  },
  fr: {
    pay: "Payer et réserver",
    book: "Réserver",
    previous: "Précédent",
    bookingInProgress: "Réservation en cours",
    ticketSelection: "Sélection de votre billet",
    payOneCard: "Paiement une carte",
    payManyCards: "Paiement plusieurs cartes",
    activatePromoCode: "Activer",
    promoCode: "Code promo",
    extraInfosTitle: "Informations requises pour la réservation",
    readAndAccept: "J'ai lu et accepte les",
    accept: "J'accept les ",
    processingConditions: "conditions de traitement des données conformes au RGPD",
    salesConditions: "conditions générales de vente",
    GDPRNoticesTitle: "Mentions d’informations conformes au RGPD",
    GDPRNoticesContent: `Les Données à caractère personnel collectées sont traitées par HOPLEISURE SAS (SIREN : 952 553 139) en sa qualité de responsable de traitement et utilisées afin de garantir la traçabilité de la réservation et de la transaction associée entre l’utilisateur et la société exploitante de l’expérience, au nom et pour le compte de laquelle HOPLEISURE SAS a mis à disposition cette technologie de réservation. 

Les informations collectées pourront être utilisées uniquement par la société exploitante de l’expérience uniquement à des fins de tenue comptable ou de démarchage commerciale. HOPLEISURE ne dispose d’aucun droit sur les Données à caractère personneL.
 
Conformément au Règlement (UE) 2016/679 du 27 avril 2016 sur la protection des Données à caractère personnel et à la loi « informatique et libertés » du 6 janvier 1978 modifiée, vous bénéficiez d’un droit d’accès, de rectification, d’effacement, de portabilité et de limitation du Traitement des Données vous concernant ainsi que du droit de communiquer des directives sur le sort de vos Données après votre mort. Vous pouvez également, pour des motifs légitimes, vous opposer au Traitement des Données vous concernant.  
 
Vous pouvez exercer vos droits en contactant Ricardo Vasques à l’adresse suivante : ricardo.vasques@hopleisure.com ou à l’adresse postale suivante : 25 RUE DES LAITIERES 94300 VINCENNES
 
Pour plus d’informations sur le Traitement de vos Données à caractère personnel par HOPLEISURE SAS, veuillez consulter notre politique de confidentialité disponible sur notre site internet à l’adresse suivante : https://hopleisure.com/politique-de-confidentialite

Les données transactionnelles anonymisées ne relevant pas de Données à caractère personnel seront en revanche exploitables par HOPLEISURE SAS y compris à des fins commerciales ou statistiques.`,
    salesConditionsTitle: "Conditions générales de vente",
    salesConditionsContent: `Le contrat de vente entre la société exploitante au nom et pour le compte de laquelle HOPLEISURE a développé cette technologie de prise de réservation et l’utilisateur est conclu dès que l’utilisateur procède au paiement sur les outils mis à disposition par HOPLEISURE SAS en partenariat avec https://stripe.com/fr.
 
Toutes les informations nécessaires à la conclusion du contrat de vente sont mises à disposition de l’utilisateur par l’outil de réservation (identité du vendeur, date, heure, prix de la réservation, cocontractant, objet de l’achat et conditions de remboursement et d’annulation…) ainsi que par le site internet sur lequel l’achat a été réalisé. L’acheteur reconnait en cochant la case “J’accepte les conditions générales de vente” avoir eu à sa disposition suffisamment d’informations pour prendre une décision d’achat éclairée.`,
    acceptConditions: "J’AI LU ET J’ACCEPTE CES CONDITIONS",
    paymentSuccessfull: {
      title: "Merci {{name}}, réservation finalisée avec succès !",
      description:
        "Vous recevrez rapidement une confirmation de réservation par email à : {{email}}",
    },
    error: {
      paymentProblem: "Le paiement a échoué",
      promoCodeNotAvailable:
        "La quantité de billets sélectionnée est supérieure à la quantité de billets disponibles pour ce code promo",
    },
    form: {
      name: {
        label: "Votre nom et prénom",
        error: "Nom et prénom sont requis",
      },
      email: {
        label: "Votre adresse email",
        error: "Adresse email est requise",
      },
      phoneNumber: {
        label: "Numéro de téléphone",
        error: "Numéro de téléphone est requis",
      },
      extraInfo: {
        error: {
          empty: "La réponse est obligatoire",
          min: "La réponse doit comporter au moins 3 caractères",
        },
      },
      checkboxes: {
        error: "Merci d'accepter les CGV et la politique de traitement des données personnelles",
      },
    },
  },
}

export default bookingPayment
