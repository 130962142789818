import { CLIENT_ID_UPLOAD_SUCCESS } from "constants/widgetConstants"

const getClientIdFromParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const clientId = urlParams.get("clientId") || ""
  return clientId
}

export function clientReducer(state = { CLIENT_ID: getClientIdFromParams() }, action: any) {
  const { type, payload } = action
  switch (type) {
    case CLIENT_ID_UPLOAD_SUCCESS:
      return { CLIENT_ID: payload }

    default:
      return state
  }
}
