import { Button } from "styled_components"
import styled from "styled-components"

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const NextStepFixedButton = styled(Button)`
  position: sticky;
  bottom: 20px;
  left: calc(100% - 20px);
  font-weight: 600;
  font-size: 20px;
`
