import { z } from "zod"
import { dateableWithTimeString, getAuthorizedConfig } from "../../helpers/helpers"
import axios from "axios"
import { BookingStatusEnum } from "../../helpers/constants"

// ----------
export const postInitPaymentInput = z.object({
  params: z.object({}),
  body: z.object({
    clientId: z.number(),
    bookingIds: z.array(z.number()),
    currency: z.enum(["USD", "EUR"]).optional(),
    paymentMethodId: z.string(),
  }),
})

export type PostPaymentInitInput = z.infer<typeof postInitPaymentInput>

export type PostPaymentInitOutput = {
  stripeClientSecret: string
  paymentId: number
  paymentMethodId: string
}

// init payment
export async function postInitPayment(
  data: PostPaymentInitInput["body"],
): Promise<PostPaymentInitOutput> {
  const config = getAuthorizedConfig("POST")
  config["url"] = `${process.env.REACT_APP_API_URL}/payment/init`
  config["data"] = data
  const response = await axios.request(config)
  const result = response.data
  return result
}

export const postConfirmPaymentInput = z.object({
  params: z.object({
    paymentId: z.number(),
  }),
  body: z.object({
    lang: z.enum(["fr", "en"]).optional(),
  }),
})

export type PostConfirmPaymentInput = z.infer<typeof postInitPaymentInput>

// confirm payment
export async function postConfirmPayment({
  paymentId,
  clientId,
  lang,
}: {
  paymentId: string | number
  clientId: number,
  lang?: "en" | "fr"
}): Promise<any[]> {
  const config = getAuthorizedConfig("POST")
  config["url"] = `${process.env.REACT_APP_API_URL}/payment/confirm/${paymentId}`
  config["data"] = {
    clientId,
    lang,
  }
  const response = await axios.request(config)
  const result = response.data
  return result
}
