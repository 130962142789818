import { Select } from "@mui/material"
import styled from "styled-components"

export const LangugageSelectorContainer = styled(Select)`
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky !important;
  top: calc(100% - 30px);
  left: 5px !important;
  cursor: pointer;
  padding: 3px;
  border-radius: 5px !important;
  background-color: white;
  border: 1px solid #c8c8c8;

  .MuiSelect-select {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    height: 20px !important;
    max-height: 20px !important;
    min-height: 20px !important;
    overflow: hidden;
  }
  .MuiSvgIcon-root {
    display: none;
  }
  fieldset {
    display: none;
  }
`
