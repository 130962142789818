const activityList: any = {
    en: {
      availableActivities: 'available activities',
      selectActivities: 'Select an activity',
      validateReservation: 'Validate reservation',
      eventFinished: 'Event finished',
      outOfStock: 'Out of stock',
      noTemporaryActivity: 'Open all year',
      from: 'From',
      to: 'to',
      hour: 'Hour',
      duration: 'Duration',
      select: 'Add to selection',
      selected: 'Selected',
      cancellationPolicy: 'Cancellation policy',
    },
    fr: {
      availableActivities: 'activités disponibles',
      selectActivities: 'Sélectionnez une activité',
      validateReservation: 'Valider Réservation',
      eventFinished: 'Évènement terminé',
      outOfStock: 'Plus de places disponibles',
      noTemporaryActivity: "Ouvert toute l'année",
      from: 'Du',
      to: 'au',
      hour: 'Heure',
      duration: 'Durée',
      select: 'Sélectionner',
      selected: 'Sélectionné',
      cancellationPolicy: "Politique d'annulation",
    },
  };
  
  export default activityList;
  