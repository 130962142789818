import { z } from "zod"

const defaultT = (key: string) => key

export const extraInfo = z.object({
  question: z.string(),
  answer: z.string(),
})

const extraInfoWithTranslation = (t = defaultT) =>
  z.object({
    question: z.string(),
    answer: z
      .string()
      .nonempty(t("form.extraInfo.error.empty"))
      .min(3, { message: t("form.extraInfo.error.empty") }),
  })

export type ExtraInfoType = z.infer<ReturnType<typeof extraInfoWithTranslation>>

export const schemaWithTranslation = (t = defaultT) =>
  z.object({
    name: z.string().nonempty({ message: t("form.name.error") }),
    email: z
      .string()
      .nonempty({ message: t("form.email.error") })
      .email({ message: t("form.email.error") }),
    phoneNumber: z.string().min(10, { message: t("form.phoneNumber.error") }),
    promotionCode: z.string().optional(),
    extraInfos: z.array(extraInfoWithTranslation(t)).max(3).optional(),
    checkboxes: z.array(z.string()).refine((arr) => arr.length === 2, {
      message: t("form.checkboxes.error"),
    }),
  })
