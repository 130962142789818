import React from "react"
import { useTranslation } from "react-i18next"
import successImg from "images/success_payment.png"
import { arePropsEqual } from "helpers/helpers"
import { useSelector } from "react-redux"
import { RootState } from "store"
import "./PaymentSuccessfull.css"

interface Props {
  visitorInfos: {
    fullName: string
    email: string
    phoneNumber: string
  }
}

const PaymentSuccessfull = ({ visitorInfos }: Props) => {
  const { t } = useTranslation("bookingPayment")
  const colors = useSelector((state: RootState) => state?.widget?.style?.colors)

  return (
    <div
      style={{
        backgroundColor: colors?.activityCardBackgroundColor,
        color: colors?.activityCardTextColor,
      }}
      className="payment-successfull"
    >
      <img src={successImg} style={{ width: "300px", maxWidth: "90vw", marginBottom: "35px" }} />
      <strong>{t("paymentSuccessfull.title", { name: visitorInfos.fullName })}</strong>
      <span>{t("paymentSuccessfull.description", { email: visitorInfos.email })}</span>
    </div>
  )
}

export default React.memo(PaymentSuccessfull, arePropsEqual)
