import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react"
import { Title } from "styled_components" // Assurez-vous que le chemin d'accès est correct
import { Activity } from "backend/api/activities" // Vérifiez également ce chemin d'accès
import { LocalizationProvider, DateCalendar } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import "dayjs/locale/fr"
import { BookingDate } from "widget/DateTicketPicker"
import { useTranslation } from "react-i18next"
import { ActivityAvailabilities } from "types/activity.type"
import { ActivitySpecialSlotTypesEnum } from "helpers/constants"
import "./BookingDatePicker.css"
import { useSelector } from "react-redux"
import { RootState } from "store"

interface Props {
  activity: Activity
  availabilitiesOnMonth: ActivityAvailabilities[]
  newBookingDate: BookingDate
  setNewBookingDate: Dispatch<SetStateAction<BookingDate>>
  setIsTimeSelected: Dispatch<SetStateAction<boolean>>
  setSelectedDay: Dispatch<SetStateAction<Date>>
  selectedDay: Date
}

const BookingDatePicker = ({
  activity,
  availabilitiesOnMonth,
  newBookingDate,
  setNewBookingDate,
  setIsTimeSelected,
  setSelectedDay,
  selectedDay,
}: Props) => {
  const { t } = useTranslation("dateTicketPicker")
  const colors = useSelector((state: RootState) => state.widget?.style?.colors)

  const minDate = dayjs(activity.hours.dates?.from || new Date())

  const enabledDays = useMemo(() => {
    return availabilitiesOnMonth
      .filter((item) => {
        return (
          item.availabilities.filter((item) => {
            const isASpecialSlotWhichRenderSlotUnavailable =
              item.specialSlotType === ActivitySpecialSlotTypesEnum.MARK_AS_FULL ||
              item.specialSlotType === ActivitySpecialSlotTypesEnum.UNAVAILABLE
            return !isASpecialSlotWhichRenderSlotUnavailable
          }).length > 0
        )
      })
      .map((item) => item.date)
  }, [availabilitiesOnMonth])

  const shouldDisableDate = (date: dayjs.Dayjs) => {
    return !enabledDays.includes(date.format("YYYY-MM-DD")) || date.isBefore(minDate, "day")
  }

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      const selectedDate = new Date(Date.UTC(date.year(), date.month(), date.date()))

      setSelectedDay(selectedDate)
      setNewBookingDate({
        ...newBookingDate,
        from: selectedDate.toISOString(),
        to: selectedDate.toISOString(),
      })
      setIsTimeSelected(false)
    }
  }

  return (
    <div
      className="booking-date-picker"
      style={{ backgroundColor: colors?.activityCardBackgroundColor }}
    >
      <Title style={{ color: colors?.activityCardTextColor }}>{t("selectDate")}</Title>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <DateCalendar
          sx={{ margin: "0" }}
          shouldDisableDate={shouldDisableDate}
          onChange={handleDateChange} // Ajout de la fonction de changement de date
          onMonthChange={handleDateChange}
        />
      </LocalizationProvider>
    </div>
  )
}

export default BookingDatePicker
