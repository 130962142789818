import { WIDGET_UPLOAD_SUCCESS } from "constants/widgetConstants"

export function widgetReducer(state = {}, action: any) {
  const { type, payload } = action
  switch (type) {
    case WIDGET_UPLOAD_SUCCESS:
      return payload

    default:
      return state
  }
}
