import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

import { Activity, getAvaibilities } from "backend/api/activities"
import { Step } from "widget"
import { Order } from "types/order.type"
import { BookingItem } from "backend/api/bookings"
import { theme } from "helpers/constants"
import { NextStepFixedButton } from "./style"
import { ActivityCard, BookingTicketPicker, BookingTimePicker, BookingDatePicker } from "components"
import { Button, Column, Row, Title } from "styled_components"
import { useQuery } from "react-query"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import "./DateTicketPicker.css"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { WidgetColorsType } from "types/widget.type"

export interface BookingDate {
  from: string
  to: string
  maxCapacity: number
}

interface Props {
  selectedActivies: Activity[]
  handleChangeStep: (step: Step) => void
  order: Order
  setOrder: Dispatch<SetStateAction<Order>>
}

const DateTicketPicker = ({ selectedActivies, handleChangeStep, order, setOrder }: Props) => {
  const { t } = useTranslation("dateTicketPicker")
  const colors = useSelector((state: RootState) => state.widget?.style?.colors) as WidgetColorsType
  const [currentActivityToBook, setCurrentActivityToBook] = useState<Activity>(selectedActivies[0])
  const [bookingStep, setBookingStep] = useState<"datePicker" | "ticketPicker">("datePicker")
  const [isTimeSlected, setIsTimeSelected] = useState<boolean>(false)
  const [selectedDay, setSelectedDay] = useState<Date>(new Date())
  const [newBookingDate, setNewBookingDate] = useState<BookingDate>({
    from: new Date().toISOString(),
    to: new Date().toISOString(),
    maxCapacity: 0,
  })
  const [newBookingInformations, setNewBookingInformations] = useState<BookingItem[]>([])

  const parseDateToFetch = (date: string) => new Date(date).toISOString().split("T")[0] || date

  const {
    data: activityAvailability,
    refetch: reftechAvailabities,
    isLoading: isAvailabilitiesLoading,
  } = useQuery({
    queryKey: [`${currentActivityToBook.id}-availability-${selectedDay}`],
    enabled: !!newBookingDate.from && !!newBookingDate.to,
    queryFn: () =>
      getAvaibilities(
        currentActivityToBook.id,
        parseDateToFetch(newBookingDate.from),
        parseDateToFetch(newBookingDate.to),
      ),
    refetchOnWindowFocus: false,
    onSuccess(data) {},
  })

  const { data: activityAvailabilityOnMonth, refetch: refetchMonthAvailabities } = useQuery({
    queryKey: [`${currentActivityToBook.id}-month-availability-${selectedDay.getMonth()}`],
    enabled: !!newBookingDate.from && !!newBookingDate.to,
    queryFn: () =>
      getAvaibilities(
        currentActivityToBook.id,
        dayjs(selectedDay).startOf("month").format("YYYY-MM-DD"),
        dayjs(selectedDay).endOf("month").format("YYYY-MM-DD"),
      ),
    refetchOnWindowFocus: false,
    onSuccess(data) {},
  })

  useEffect(() => {
    refetchMonthAvailabities()
  }, [selectedDay])

  const handleBack = () => {
    if (bookingStep === "ticketPicker") {
      setBookingStep("datePicker")
    } else {
      const currentActivityToBookIndex = selectedActivies.findIndex(
        (activity) => activity.id === currentActivityToBook.id,
      )
      if (currentActivityToBookIndex === 0) {
        // reset all bookings if go to activity list
        setOrder((prevOrder) => {
          return {
            ...prevOrder,
            selected_activities: [],
          }
        })
        handleChangeStep("activityList")
      } else {
        // reset previous booking if go to previous activity ticket picker
        setOrder((prevOrder) => {
          return {
            ...prevOrder,
            bookings: prevOrder.bookings.filter(
              (booking, index) => booking.activity_id !== currentActivityToBook.id,
            ),
          }
        })
        setCurrentActivityToBook(selectedActivies[currentActivityToBookIndex - 1])
      }
    }
  }

  const handleNext = (newBookingInformationsParams: BookingItem[] = newBookingInformations) => {
    setIsTimeSelected(false)

    if (bookingStep === "datePicker") {
      setBookingStep("ticketPicker")
    }

    if (bookingStep === "ticketPicker" && newBookingInformationsParams) {
      setOrder((prevOrder) => {
        return {
          ...prevOrder,
          bookings: newBookingInformationsParams,
        }
      })

      const currentActivityToBookIndex = selectedActivies.findIndex(
        (activity) => activity.id === currentActivityToBook.id,
      )

      const isLastActivityToBook = currentActivityToBookIndex === selectedActivies.length - 1

      // reset states
      setBookingStep("datePicker")
      setNewBookingDate({ from: "", to: "", maxCapacity: 0 })

      if (isLastActivityToBook) {
        setNewBookingInformations([])
        handleChangeStep("payment")
      } else {
        setCurrentActivityToBook(selectedActivies[currentActivityToBookIndex + 1])
      }
    }
  }

  /* TO AUTOMATICALY CONFIRM AT TIME SELECTION */
  useEffect(() => {
    if (isTimeSlected) {
      handleNext(newBookingInformations)
    }
  }, [isTimeSlected])

  return (
    <>
      <Row space width="100%">
        <Button
          secondary
          onClick={handleBack}
          style={{
            color: colors?.actionBtnTextColor,
            backgroundColor: colors?.actionBtnBackgroundColor,
          }}
        >
          <ArrowBackIcon
            style={{
              color: colors?.actionBtnTextColor ?? theme.color.primary,
              fontSize: "25px",
              marginRight: "4px",
            }}
          />
          {t("previous")}
        </Button>
        {isTimeSlected && bookingStep === "datePicker" && (
          <Button
            onClick={() => handleNext(newBookingInformations)}
            style={{
              color: colors?.actionBtnTextColor,
              backgroundColor: colors?.actionBtnBackgroundColor,
            }}
          >
            {t("next")}{" "}
            <ArrowForwardIcon
              style={{ color: colors?.actionBtnTextColor ?? "white", fontSize: "25px" }}
            />
          </Button>
        )}
      </Row>

      <Row
        width="100%"
        margin="20px 0"
        space
        mobile="flex-direction: column; margin-top: 20px; margin: 20px 0 30px 0"
      >
        <Title
          style={{
            color: colors?.textColorOnBackground,
          }}
        >
          {t("bookingInProgress")}
        </Title>
        <Title
          mobile="font-size: 14px; margin-top: 5px;"
          grey
          style={{
            color: colors?.textColorOnBackground,
          }}
        >
          {t("ticketSelection")}
        </Title>
      </Row>
      <div
        className="activity-calendar-container"
        style={{ gridTemplateColumns: bookingStep === "datePicker" ? "70% 1fr" : "100%" }}
      >
        <ActivityCard
          key={currentActivityToBook.id}
          activity={currentActivityToBook}
          isCarouselActive={bookingStep === "datePicker"}
        />
        {bookingStep === "datePicker" && (
          <div className="date-picker-container">
            <BookingDatePicker
              activity={currentActivityToBook}
              availabilitiesOnMonth={activityAvailabilityOnMonth || []}
              newBookingDate={newBookingDate}
              setNewBookingDate={setNewBookingDate}
              setIsTimeSelected={setIsTimeSelected}
              setSelectedDay={setSelectedDay}
              selectedDay={selectedDay}
            />
            <BookingTimePicker
              availabilities={activityAvailability?.[0]?.availabilities}
              newBookingDate={newBookingDate}
              setNewBookingDate={setNewBookingDate}
              setIsTimeSelected={setIsTimeSelected}
              isAvailabilitiesLoading={isAvailabilitiesLoading}
            />
          </div>
        )}

        {bookingStep === "ticketPicker" && (
          <BookingTicketPicker
            activity={currentActivityToBook}
            newBookingInformations={newBookingInformations}
            setNewBookingInformations={setNewBookingInformations}
            newBookingDate={newBookingDate}
            handleNext={handleNext}
          />
        )}

        {isTimeSlected && bookingStep === "datePicker" && (
          <NextStepFixedButton onClick={() => handleNext(newBookingInformations)}>
            {t("next")} <ArrowForwardIcon style={{ color: "white", fontSize: "25px" }} />
          </NextStepFixedButton>
        )}
      </div>
    </>
  )
}

export default DateTicketPicker
