import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MenuItem } from "@mui/material"

import { LangugageSelectorContainer } from "./style"
import frFlag from "images/fr.png"
import enFlag from "images/en.png"

export default function LanguageSelector() {
  const [language, setLanguage] = useState("fr")
  const { i18n } = useTranslation("auth")

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  return (
    <LangugageSelectorContainer
      value={language}
      // @ts-ignore
      onChange={(e) => setLanguage(e.target.value)}
      label="language"
    >
      <MenuItem value={"fr"}>
        <img src={frFlag} alt="fr" style={{ width: 30 }} />
      </MenuItem>
      <MenuItem value={"en"}>
        <img src={enFlag} alt="en" style={{ width: 30 }} />
      </MenuItem>
    </LangugageSelectorContainer>
  )
}
