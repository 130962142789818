import React from "react"
import { Button, Column, Row, Title } from "styled_components"
import { BookingResume } from "components"

import { Order } from "types/order.type"
import { theme } from "helpers/constants"
import { Step } from "widget"
import PaymentForm from "components/PaymentForm"
import { useTranslation } from "react-i18next"
import { Discount } from "backend/api/discounts"
import { Activity } from "backend/api/activities"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useSelector } from "react-redux"
import { RootState } from "store"

interface Props {
  order: Order
  stripeAccountId: string
  activity: Activity
  handleChangeStep: (step: Step) => void
}

const BookingPayment = ({ order, stripeAccountId, activity, handleChangeStep }: Props) => {
  const { t } = useTranslation("bookingPayment")
  const [discounts, setDiscounts] = React.useState<Discount[]>([])
  const colors = useSelector((state: RootState) => state?.widget?.style?.colors)

  const handleBack = () => {
    handleChangeStep("dateTicketPicker")
  }
  return (
    <Column width="100%">
      <Row space width="100%">
        <Button
          secondary
          onClick={handleBack}
          style={{
            backgroundColor: colors?.actionBtnBackgroundColor,
            color: colors?.actionBtnTextColor,
          }}
        >
          <ArrowBackIcon
            style={{
              color: colors?.actionBtnTextColor,
              fontSize: "25px",
              marginRight: "4px",
            }}
          />
          {t("previous")}
        </Button>
      </Row>

      <Row
        width="100%"
        margin="20px 0"
        space
        mobile="flex-direction: column; margin-top: 20px; margin: 20px 0 30px 0"
      >
        <Title
          style={{
            color: colors?.textColorOnBackground,
          }}
        >
          {t("bookingInProgress")}
        </Title>
        <Title
          mobile="font-size: 14px; margin-top: 5px;"
          grey
          style={{
            color: colors?.textColorOnBackground,
          }}
        >
          {t("ticketSelection")}
        </Title>
      </Row>

      <BookingResume order={order} activity={activity} discounts={discounts} />
      <PaymentForm
        order={order}
        stripeAccountId={stripeAccountId}
        setDiscounts={setDiscounts}
        discounts={discounts}
        activity={activity}
        handleBack={handleBack}
      />
    </Column>
  )
}

export default BookingPayment
