import React, { Dispatch, SetStateAction, useState } from "react"
import { Select, MenuItem, SelectChangeEvent, Divider } from "@mui/material"
import { Title, Button, Row, Column, Text } from "styled_components"
import { TicketOption, TicketOptionsContainer } from "./style"
import { Activity } from "backend/api/activities"
import { BookingItem } from "backend/api/bookings"
import { BookingDate } from "widget/DateTicketPicker"
import {
  ActivityPricesStatusEnum,
  ActivityPricesTypeEnum,
  ActivityTypeEnum,
  BookingSourceEnum,
  BookingStatusEnum,
} from "helpers/constants"
import dayjs from "dayjs"
import "./BookingTicketPicker.css"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "store"

interface PickingTicket {
  name: string
  quantity: number
}

interface Props {
  activity: Activity
  newBookingInformations: BookingItem[]
  setNewBookingInformations: Dispatch<SetStateAction<BookingItem[]>>
  newBookingDate: BookingDate
  handleNext: (newBookingInformations: BookingItem[]) => void
}

const TicketPicker = ({
  activity,
  newBookingInformations,
  setNewBookingInformations,
  newBookingDate,
  handleNext,
}: Props) => {
  const { t } = useTranslation("dateTicketPicker")
  const colors = useSelector((state: RootState) => state.widget?.style?.colors)

  const [availableQuantity, setAvailableQuantity] = useState(
    activity.informations.bookingSettings.type === ActivityTypeEnum.GROUP
      ? activity.informations.bookingSettings.maxUnitsPerGroup
      : newBookingDate.maxCapacity,
  )
  const [quantities, setQuantities] = useState<PickingTicket[]>([])

  const filteredPrices = activity.prices.filter(
    (price) => price.status === ActivityPricesStatusEnum.ENABLED,
  )

  const handleChange = (name: string, event: SelectChangeEvent<any>) => {
    const newQuantity = parseInt(event.target.value, 10) // Parse the new quantity value from the event
    const existingTicket = quantities.find((q) => q.name === name) // Find if an entry already exists
    const previousQuantity = existingTicket ? existingTicket.quantity : 0 // Get previous quantity, defaulting to 0 if not found

    // Update the quantities array:
    // - If the ticket already exists, update its quantity
    // - If it does not exist and the new quantity is not zero, add a new entry
    if (existingTicket) {
      setQuantities(quantities.map((q) => (q.name === name ? { ...q, quantity: newQuantity } : q)))
    } else if (newQuantity !== 0) {
      setQuantities([...quantities, { name, quantity: newQuantity }])
    } else {
      // If new quantity is zero and the ticket does not exist, no need to add it
      setQuantities(quantities.filter((q) => q.name !== name))
    }

    // Update available quantity:
    // Increase or decrease based on the new quantity compared to the previous quantity
    setAvailableQuantity(availableQuantity - newQuantity + previousQuantity)
  }

  const handleValid = () => {
    const newTickets: BookingItem[] = quantities
      .map((quantity) => {
        const ticket = activity.prices.find((price) => price.name === quantity.name)
        return {
          from: newBookingDate.from,
          to: newBookingDate.to,
          activity_id: activity.id,
          numberOfUnit: quantity.quantity,
          priceName: ticket?.name || "",
          price: Number(ticket?.price) || 0,
          status: BookingStatusEnum.BOOKED,
          source: BookingSourceEnum.WIDGET,
        }
      })
      .filter((ticket: BookingItem | undefined) => ticket !== undefined)

    if (newTickets.length) {
      setNewBookingInformations([...newBookingInformations, ...newTickets])
    }
    handleNext([...newBookingInformations, ...newTickets])
  }

  return (
    <div className="booking-ticket-picker-container">
      <div
        className="booking-ticket-picker-time-infos"
        style={{ textWrap: "nowrap", color: colors?.textColorOnBackground }}
      >
        <Title
          margin="0 0 5px 0"
          bold
          style={{ textWrap: "nowrap", color: colors?.textColorOnBackground }}
        >
          Date :
          <Title margin="0 0 0 5px" style={{ color: colors?.textColorOnBackground }}>
            {dayjs(newBookingDate.from).format("DD/MM/YYYY")}
          </Title>
          <Title margin="0 0 0 20px" bold style={{ color: colors?.textColorOnBackground }}>
            {t("hour")} :{" "}
            <Title style={{ color: colors?.textColorOnBackground }} margin="0 20px 0 5px">
              {dayjs(newBookingDate.from).format("HH[h]mm")}
            </Title>
          </Title>
          {t("duration")} :{" "}
          <Title margin="0 0 0 5px" style={{ color: colors?.textColorOnBackground }}>
            {`${dayjs(newBookingDate.to).diff(dayjs(newBookingDate.from), "hour").toString().padStart(2, "0")}h` +
              `${(dayjs(newBookingDate.to).diff(dayjs(newBookingDate.from), "minute") % 60).toString().padStart(2, "0")}`}
          </Title>
        </Title>
      </div>
      <div
        className="booking-ticket-picker"
        style={{
          backgroundColor: colors?.activityCardBackgroundColor,
        }}
      >
        {filteredPrices.map((option, index) => {
          const selectedQuantity = quantities.find(
            (quantity) => quantity.name === option.name,
          )?.quantity
          const currentAvailableQuantity =
            selectedQuantity && selectedQuantity >= availableQuantity
              ? selectedQuantity + availableQuantity
              : availableQuantity

          return (
            <div key={index}>
              <div
                key={index + currentAvailableQuantity}
                style={{
                  color: colors?.activityCardTextColor,
                }}
                className="ticket-option"
              >
                <span>
                  <strong>
                    {activity.informations.name} - {option.type ? t(option.type) : option.name}
                  </strong>
                </span>
                <div className="ticket-content">
                  <div>
                    {option.type === ActivityPricesTypeEnum.GROUP && (
                      <span style={{ fontWeight: 600 }}>{t("personsNumberInGoup")}</span>
                    )}
                    <div className="ticket-price">
                      <span>
                        {option.description} : {parseFloat(String(option.price)).toFixed(2)}€
                      </span>
                      <Select
                        value={selectedQuantity || 0}
                        onChange={(e) => handleChange(option.name, e)}
                        displayEmpty
                        style={{
                          color: colors?.activityCardTextColor,
                          backgroundColor: colors?.activityCardBackgroundColor,
                        }}
                      >
                        <MenuItem
                          key={0}
                          value={0}
                          style={{
                            color: colors?.activityCardTextColor,
                            backgroundColor: colors?.activityCardBackgroundColor,
                          }}
                        >
                          0
                        </MenuItem>
                        {/* @ts-ignore */}
                        {[...Array(currentAvailableQuantity + 1 || 1).keys()].map(
                          (x) =>
                            x > 0 && (
                              <MenuItem
                                key={x}
                                value={x}
                                style={{
                                  color: colors?.activityCardTextColor,
                                  backgroundColor: colors?.activityCardBackgroundColor,
                                }}
                              >
                                {x}
                              </MenuItem>
                            ),
                        )}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <Divider style={{ marginBottom: "30px" }} />
            </div>
          )
        })}
        {quantities.length > 0 && (
          <Button
            width="100%"
            onClick={handleValid}
            style={{
              color: colors?.actionBtnTextColor,
              backgroundColor: colors?.actionBtnBackgroundColor,
              border: "0.8px solid #646464",
            }}
          >
            {t("validate")}
          </Button>
        )}
      </div>
    </div>
  )
}

export default TicketPicker
