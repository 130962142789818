import { Modal } from "@mui/material"
import { Button } from "styled_components"
import "./ConditionsModal.css"
import { RootState } from "store"
import { useSelector } from "react-redux"
import { arePropsEqual } from "helpers/helpers"
import React from "react"

const ConditionsModal = (props: {
  t: (key: string) => string
  isConditionsModalOpen: boolean
  setIsConditionsModalOpen: (isOpen: boolean) => void
  handleCheckBoth: () => void
}) => {
  const { isConditionsModalOpen, setIsConditionsModalOpen, t, handleCheckBoth } = props
  const colors = useSelector((state: RootState) => state?.widget?.style?.colors)

  return (
    <Modal open={isConditionsModalOpen} onClose={() => setIsConditionsModalOpen(false)}>
      <div
        className="conditions-modal"
        style={{
          backgroundColor: colors?.activityCardBackgroundColor,
        }}
      >
        <div
          style={{
            backgroundColor: colors?.activityCardBackgroundColor,
            color: colors?.activityCardTextColor,
          }}
        >
          <div className="conditions-container">
            <span className="conditions-title">{t("GDPRNoticesTitle")}</span>
            <pre className="conditions-content">{t("GDPRNoticesContent")}</pre>
          </div>
        </div>
        <div
          style={{
            color: colors?.activityCardTextColor,
          }}
        >
          <div className="conditions-container">
            <span className="conditions-title">{t("salesConditionsTitle")}</span>
            <pre className="conditions-content">{t("salesConditionsContent")}</pre>
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              handleCheckBoth()
              setIsConditionsModalOpen(false)
            }}
            style={{
              marginLeft: "auto",
              backgroundColor: colors?.actionBtnBackgroundColor,
              color: colors?.actionBtnTextColor,
              fontSize: "14px",
            }}
          >
            {t("acceptConditions")}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(ConditionsModal, arePropsEqual)
