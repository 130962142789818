import React from "react"
import { Skeleton } from "@mui/material"

const Skeletons = () => {
  return (
    <>
      <Skeleton variant="rounded" width="100%" height={60} sx={{ mb: 4, mt: 2 }} />
      <Skeleton variant="rounded" width="100%" height={60} sx={{ mb: 4 }} />
      <Skeleton variant="rounded" width="100%" height={60} sx={{ mb: 2 }} />
    </>
  )
}

export default Skeletons
