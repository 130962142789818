import React, { useEffect, useMemo, useState } from "react"

import { ActivityCard } from "components"
import { Order } from "types/order.type"
import Divider from "@mui/material/Divider"
import { Discount } from "backend/api/discounts"
import { getBookingsPrice } from "backend/api/bookings"
import { Activity } from "backend/api/activities"
import { ActivityTypeEnum } from "helpers/constants"
import { isNumber } from "lodash"
import "./BookingSummary.css"
import { useSelector } from "react-redux"
import { RootState } from "store"

interface Props {
  order: Order
  activity: Activity
  discounts: Discount[]
}

const BookingSummary = ({ order, discounts, activity }: Props) => {
  const [totalPriceWithDiscount, setTotalPriceWithDiscount] = useState<number | undefined>()
  const colors = useSelector((state: RootState) => state?.widget?.style?.colors)

  useEffect(() => {
    calculatePriceWithDiscount()
  }, [discounts])

  const calculatePriceWithDiscount = async () => {
    if (discounts && discounts.length > 0) {
      let priceWithDiscount = 0
      for (const activity of order.selected_activities) {
        const discount = discounts.find((discount) => discount.activityId === activity.id)
        const activityBookings = order.bookings.filter(
          (booking) => booking.activity_id === activity.id,
        )
        const priceForOrder = await getBookingsPrice(activity.id, activityBookings, discount?.code)
        priceWithDiscount += priceForOrder.price
      }
      setTotalPriceWithDiscount(priceWithDiscount)
    }
  }
  const isGroupActivity = useMemo(
    () => activity.informations.bookingSettings.type === ActivityTypeEnum.GROUP,
    [activity],
  )

  return (
    <>
      {order.selected_activities.map((activity, index) => {
        const isLastIndex = index === order.selected_activities.length - 1
        const activityBookings = order.bookings.filter(
          (booking) => booking.activity_id === activity.id,
        )
        const bookingDate = order.bookings.find((booking) => booking.activity_id === activity.id)
        const totalPrice = isGroupActivity
          ? order.bookings.reduce((acc, booking) => acc + booking.price, 0)
          : order.bookings.reduce((acc, booking) => acc + booking.price * booking.numberOfUnit, 0)
        const discount: Discount | undefined = discounts.find(
          (discount) => discount.activityId === activity.id,
        )
        return (
          <div key={index} className="booking-summary">
            <div className="activity-infos">
              <ActivityCard activity={activity} bookingDate={bookingDate} />
              <div
                className="booking-price"
                style={{
                  backgroundColor: colors?.activityCardBackgroundColor,
                  color: colors?.activityCardTextColor,
                }}
              >
                {activityBookings.map((booking, index) => {
                  return (
                    <div key={index} className="booking-info-container">
                      <div className="booking-info">
                        <strong>
                          {activity.informations.name} - {booking.priceName}
                        </strong>
                        <div>
                          <span>{parseFloat(booking.price.toString()).toFixed(2)}€</span>
                          {!isGroupActivity && <span>x{booking.numberOfUnit}</span>}
                        </div>
                      </div>
                      <Divider style={{ marginBottom: "20px", width: "100%" }} />
                    </div>
                  )
                })}
                <strong>
                  Total :{" "}
                  {isNumber(totalPriceWithDiscount) ? (
                    <strong>
                      <span style={{ textDecorationLine: "line-through", margin: "0 10px 0 10px" }}>
                        {totalPrice.toFixed(2)}€
                      </span>

                      <span>{totalPriceWithDiscount.toFixed(2)}€</span>
                    </strong>
                  ) : (
                    <strong style={{ marginLeft: "10px" }}> {totalPrice.toFixed(2)}€</strong>
                  )}
                </strong>
              </div>
            </div>
            {!isLastIndex && <Divider style={{ margin: "40px 0 50px 0", width: "100%" }} />}
          </div>
        )
      })}
    </>
  )
}

export default BookingSummary
