import React from "react"
import { useTranslation } from "react-i18next"

import { Modal } from "@mui/material"
import { Text, Title } from "styled_components"
import styled from "styled-components"

interface Props {
  isOpen: boolean
  handleClose: () => void
  cancellationPolicy: string
}

const CancellationPolicyModal = ({ isOpen, handleClose, cancellationPolicy }: Props) => {
  const { t } = useTranslation("activityList")

  return (
    <Modal onClose={handleClose} open={isOpen}>
      <ModalContent>
        <Title style={{ textAlign: "center" }} size="20px" margin="0 0 25px 0">
          {t("cancellationPolicy")}
        </Title>

        <Text>{cancellationPolicy}</Text>
      </ModalContent>
    </Modal>
  )
}

export default CancellationPolicyModal

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 800px;
  max-width: 80vw;
  max-height: 70vh;
  overflow: auto;
`
