import { Button, Column } from "styled_components"
import styled from "styled-components"
import { theme } from "helpers/constants"

export const TimeSlotButton = styled(Button)<{ selected?: boolean }>`
  width: 100%;
  margin: 8px 0;
`
export const TimePickerContainer = styled(Column)`
  padding: 0 2rem 0 0;
  ${theme.mediaQueries.mobile} {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
