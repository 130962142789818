import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import activityList from './activityList';
import dateTicketPicker from './dateTicketPicker';
import bookingPayment from './bookingPayment';
import error from './error';
import general from './general'

const resources = {
  en: {
    general: general.en,
    activityList: activityList.en,
    dateTicketPicker: dateTicketPicker.en,
    bookingPayment: bookingPayment.en,
    error: error.en,
  },
  fr: {
    general: general.fr,
    activityList: activityList.fr,
    dateTicketPicker: dateTicketPicker.fr,
    bookingPayment: bookingPayment.fr,
    error: error.fr,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
    lng: 'fr',
  });

export default i18n;
