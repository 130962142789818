import axios from "axios"
import { z } from "zod"
import { getAuthorizedConfig } from "../../helpers/helpers"
import { DiscountTypesEnum } from "helpers/constants"

const discount = z.object({
  id: z.number().optional(),
  activityId: z.number(),
  discountType: z.enum([DiscountTypesEnum.PERCENTAGE, DiscountTypesEnum.SUBSTRACTION]),
  discountNumber: z.number(),
  code: z.string(),
  isCseDiscount: z.boolean(),
  maxUsage: z.number().optional(),
  nbOfUsages: z.number().optional(),
})

export type Discount = z.infer<typeof discount>

export async function getDiscount({
  code,
  activityId,
}: {
  code: string
  activityId: string | number
}): Promise<Discount> {
  const config = getAuthorizedConfig("GET")
  config["url"] = `${process.env.REACT_APP_API_URL}/discounts/${code}/activity/${activityId}`
  const response = await axios.request(config)
  const result = response.data
  return result
}
