import React, { Dispatch, SetStateAction, useState } from "react"
import { useQuery } from "react-query"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import DiscountIcon from "@mui/icons-material/Discount"
import { Order } from "types/order.type"
import { useTranslation } from "react-i18next"
import { Discount, getDiscount } from "backend/api/discounts"
import { Column, Row } from "styled_components"
import { theme } from "helpers/constants"
import { useSelector } from "react-redux"
import { RootState } from "store"

const PaymentForm = ({
  order,
  setDiscounts,
  discountCode,
  setDiscountCode,
}: {
  order: Order
  setDiscounts: Dispatch<SetStateAction<Discount[]>>
  discountCode: string
  setDiscountCode: Dispatch<SetStateAction<string>>
}) => {
  const { t } = useTranslation("bookingPayment")
  const [alertMessage, setAlertMessage] = useState<string>("")
  const colors = useSelector((state: RootState) => state?.widget?.style?.colors)

  const fetchDiscounts = async () => {
    const promises = order.selected_activities.map((activity) =>
      getDiscount({ code: discountCode, activityId: activity.id }),
    )
    return Promise.all(promises)
  }

  const checkDiscountAvailability = (discounts: Discount[], order: Order) => {
    let isSelectedTicketsSuperiorToAvailableDiscounts = false
    const totalQuantityPerActivity: any = {}
    for (const booking of order.bookings) {
      if (totalQuantityPerActivity[booking.activity_id]) {
        totalQuantityPerActivity[booking.activity_id] += booking.numberOfUnit
      } else {
        totalQuantityPerActivity[booking.activity_id] = booking.numberOfUnit
      }
    }
    for (const discount of discounts) {
      const discountAvailability = (discount.maxUsage || 0) - (discount.nbOfUsages || 0)
      if (
        discount &&
        discount.isCseDiscount &&
        discountAvailability < totalQuantityPerActivity[discount.activityId]
      ) {
        isSelectedTicketsSuperiorToAvailableDiscounts = true
        break
      }
    }
    return isSelectedTicketsSuperiorToAvailableDiscounts
  }

  const {
    data: discounts,
    isLoading,
    refetch,
  } = useQuery(["discounts", discountCode], fetchDiscounts, {
    enabled: false,
    onSuccess: (newDiscounts) => {
      const isSelectedTicketsSuperiorToAvailableDiscounts = checkDiscountAvailability(
        newDiscounts,
        order,
      )
      if (isSelectedTicketsSuperiorToAvailableDiscounts) {
        setAlertMessage(t("error.promoCodeNotAvailable"))
        setDiscountCode("")
      } else {
        setDiscounts(newDiscounts.filter((discount) => !!discount))
      }
    },
  })

  const onCheckDiscount = () => {
    if (discountCode) {
      refetch()
    }
  }

  return (
    <Column width="100%">
      <Row width="100%">
        <TextField
          onChange={(e) => setDiscountCode(e.target.value)}
          label={t("promoCode")}
          fullWidth
          variant="outlined"
          margin="normal"
          sx={{ margin: "15px 15px 10px 0", borderBottom: "1px solid black", width: "100%" }}
          InputProps={{
            renderSuffix: () => (
              <DiscountIcon style={{ color: theme.color.primary, marginRight: "5px" }} />
            ),
            sx: { width: "100%" },
          }}
        />
        {discountCode && (
          <Button
            variant="contained"
            color="primary"
            onClick={onCheckDiscount}
            style={{
              marginTop: "25px",
              height: "45px",
              marginLeft: "10px",
              backgroundColor: colors?.actionBtnBackgroundColor,
              color: colors?.actionBtnTextColor,
            }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : t("activatePromoCode")}
          </Button>
        )}
      </Row>
      {alertMessage && <p style={{ color: "red" }}>{alertMessage}</p>}
    </Column>
  )
}

export default PaymentForm
