import React from "react"
import { useTranslation } from "react-i18next"

import errorImg from "images/time_expired.jpeg"
import { Modal } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Button, Column, Title } from "styled_components"
import styled from "styled-components"

interface Props {
  error: string
  handleBack: () => void
}

const ErrorModal = ({ error, handleBack }: Props) => {
  const { t } = useTranslation("error")

  return (
    <Modal open={true}>
      <ModalContent>
        <img src={errorImg} style={{ maxHeight: "50vh", maxWidth: "100%" }} />
        <Title style={{ textAlign: "center" }} size="20px">
          {t(error)}
        </Title>

        <Button onClick={handleBack} style={{ marginTop: "25px" }}>
          <ArrowBackIcon style={{ color: "white", height: "25px", marginRight: "10px" }} />
          {t("actions.backToEventsList")}
        </Button>
      </ModalContent>
    </Modal>
  )
}

export default ErrorModal

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 800px;
  max-width: 80vw;
  max-height: 70vh;
  overflow: auto;
`
